
var $products = $('.products');

$products.find('.subtract').click(function(e) {
    var $amountInput = $(this).siblings('.amount').find('input');
    var amount = parseInt($amountInput.val()) || 0;

    updateAmount($amountInput, amount - 1)
});

$products.find('.add').click(function(e) {
    var $amountInput = $(this).siblings('.amount').find('input');
    var amount = parseInt($amountInput.val()) || 0;

    updateAmount($amountInput, amount + 1)
});

function updateAmount($input, amount) {
    amount = amount || 0;
    amount = Math.max(amount, 1);

    $input.val('' + amount);
}