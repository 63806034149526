
var $currentCustomer = $('#current-customer');
var expiryDays = 56;

$currentCustomer.change(function(e) {
    setCookie('customer_number', e.target.value, expiryDays);
    window.location.reload();
});

//var items = {'10157': 2, '10154': 2};
//saveCartItems(items);
renderShoppingCartButton();

$('.add-to-cart').click(function(e) {
   e.preventDefault();

   var $this = $(this);

   var $row = $this.parents('.row');
   var productId = parseInt($row.find('input.product-id').val()) || 0;
   var amount = parseFloat($row.find('.amount').find('input').val().replace(',', '.')) || 0;

   if (productId && amount) {
       addItem(productId, amount);
       renderShoppingCartButton();
   }

   var $shoppingCart = $('#shopping-cart');
   $shoppingCart.css('transition', 'background 100ms').css('background', '#F20A37');
   setTimeout(function() {
       $shoppingCart.css('transition', 'background 1000ms');
       $shoppingCart[0].style.background = '';
       setTimeout(function() {
           $shoppingCart[0].style.transition = '';
       }, 500);
   }, 200);

   var $i = $this.find('i');
   $i.removeClass('fa-cart-plus').addClass('fa-circle-notch fa-spin');
   setTimeout(function() {
       $i.removeClass('fa-circle-notch fa-spin').addClass('fa-check');
       setTimeout(function() {
           $i.removeClass('fa-check').addClass('fa-cart-plus');
       }, 2000);
   }, 500);
});

$('.shopping-cart .remove-item').click(function(e) {
    e.preventDefault();

    var $row = $(this).parents('.row');
    var index = $row.data('index');

    removeItem(index);

    $row.fadeOut(function() {
        $row.remove();
        updateCartTotal();
        renderShoppingCartButton();

        if (getCartTotal() <= 0) {
            window.location.reload();
        }
    });
});

$('.shopping-cart .subtract').click(function(e) {
    e.preventDefault();

    var $row = $(this).parents('.row');
    updateCartItem($row, 'subtract');
});

$('.shopping-cart .send-order').click(function(e) {
    e.preventDefault();

    var $form = $(this).parents('form');
    $form.submit();
})

$('.shopping-cart .add').click(function(e) {
    e.preventDefault();

    var $row = $(this).parents('.row');
    updateCartItem($row, 'add');
});

$('.shopping-cart .count input').change(function() {
    var $row = $(this).parents('.row');
    updateCartItem($row, 'update');
});

// method: 'subtract', 'add', 'update'
function updateCartItem($row, method) {
    var index = $row.data('index');

    var items = getCartItems();
    var currentCount = items[index].count || 0;
    var newCount = 0;

    switch (method) {
        case 'add':
            newCount = currentCount + 1;
            break;
        case 'subtract':
            newCount = currentCount - 1;
            break;
        case 'update':
            newCount = parseFloat($row.find('.count input').val().replace(',', '.')) || 0;
            break;
    }

    var price = parseFloat($row.find('.price input').val()) || 0;

    newCount = Math.max(newCount, 0);
    var item = items[index];
    item.count = newCount;
    items[index] = item;

    $row.find('.count input').val(newCount.toLocaleString('no'));
    $row.find('.total').html(priceFormat(price * newCount));

    saveCartItems(items);
    updateCartTotal();
    renderShoppingCartButton();
}

function updateCartTotal() {
    var total = getCartTotal();
    $('.shopping-cart .foot .price.total').html(priceFormat(total));
}

function getCartTotal() {
    var total = 0;

    $('.shopping-cart .table .row:not(.foot)').each(function(i, tr) {
        var $row = $(tr);
        var count = parseFloat($row.find('.count input').val().replace(',', '.')) || 0;
        var price = parseFloat($row.find('.price input').val()) || 0;

        total += count * price;
    });

    return total;
}

function renderShoppingCartButton() {
    var $shoppingCart = $('#shopping-cart');

    if ($shoppingCart.length === 0) {
        return;
    }

    var $span = $shoppingCart.find('span');

    var items = getCartItems();

    $span.html('' + items.length + (items.length === 1 ? ' vare' : ' varer'));
}

function priceFormat(price) {
    return price.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$& ') + ',-';
}

function getCartItems() {
    try {
        var cartItems = JSON.parse(getCookie('shopping_cart')) || [];

        // Convert old cart items to new format
        if (!Array.isArray(cartItems)) {
            var items = [];
            var keys = Object.keys(cartItems);
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                var newObject = {
                    productId: key,
                    count: cartItems[keys[i]]
                }
                items.push(newObject);
            }
            return items;
        }

        return cartItems;
    } catch (e) {
        return {};
    }
}

function saveCartItems(items) {
    setCookie('shopping_cart', JSON.stringify(items), expiryDays);
}

function addItem(id, addCount) {
    var items = getCartItems();

    items.push({
        productId: id,
        count: addCount
    });

    saveCartItems(items);
}

function removeItem(index) {
    var items = getCartItems();

    items = items.slice(0, index).concat(items.slice(index + 1));

    saveCartItems(items);
}

function setCookie(name, value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name+'=; Max-Age=-99999999;';
}
