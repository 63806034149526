var imageGalleries = document.getElementsByClassName('image-gallery');
for (var i = 0; i < imageGalleries.length; i++) {
    (function (gallery) {
        var imageLinks = gallery.getElementsByTagName('a');
        var mainImage = gallery.getElementsByClassName('main-image')[0];
        for (var j = 0; j < imageLinks.length; j++) {
            (function (link) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();

                    var imageCopy = link.getElementsByTagName('img')[0].cloneNode();
                    var captionCopy = link.getElementsByClassName('caption')[0].cloneNode(true);
                    mainImage.innerHTML = '';
                    mainImage.append(imageCopy);
                    mainImage.append(captionCopy);

                    for (var k = 0; k < imageLinks.length; k++) {
                        imageLinks[k].classList.remove('active');
                    }

                    link.classList.add('active');
                });
            })(imageLinks[j]);
        }
    })(imageGalleries[i]);
}